<template>
    <div class="roadFront">
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="建设单位">
                                        <el-input style="width: 180px" size="medium" v-model="JSDW" placeholder="请输入" clearable></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">删除</el-button>
                                    </el-form-item>

                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                    <el-button @click="addRoad" type="primary" size="mini" round icon="el-icon-plus">上传</el-button>
                                </span>
                                <h3 class="tit">确认函列表</h3>
                            </div>
                            <div class="box-content">

                                <el-table  @sort-change="changeSort" @row-dblclick="openDetailsEdit" ref="table" :data="tableData" size="small " :height="tableHeight" border  style="width: 100%">
                                    <el-table-column  prop="" align="center" :width="135" label="操作">
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons iconsyes"></i>编辑</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="index"  label="序号"  align="center" type="index" :width="50" :index="1+30*(currentPage-1)"></el-table-column>
                                    <el-table-column prop="JSDW" label="建设单位" align="center" show-overflow-tooltip :width="350"></el-table-column>
                                    <el-table-column prop="CreateDate" label="上传时间" align="center" show-overflow-tooltip ></el-table-column>
                                    <el-table-column prop="QRHFJ" label="确认函" align="center" show-overflow-tooltip >
                                        <template slot-scope="scope">
                                            <el-popover trigger="hover" placement="top" v-if="scope.row.QRHFJ.length > 0">
                                                <ul class="fj-list">
                                                    <li v-for="(item,i) in scope.row.QRHFJ" :key="i">
                                                        <a target="view_window" :href="baseUrl+item.url+item.name">
                                                            {{item.name}}
                                                        </a>
                                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                                    </li>
                                                </ul>

                                                <div slot="reference" class="name-wrapper">
                                                    <el-tag :type="scope.row.QRHFJ.length>0?'success':'danger'" size="small">已上传{{scope.row.QRHFJ.length}}个</el-tag>
                                                </div>
                                            </el-popover>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="TJBFJ" label="统计表" align="center" show-overflow-tooltip >
                                        <template slot-scope="scope">
                                            <el-popover trigger="hover" placement="top" v-if="scope.row.TJBFJ.length > 0">
                                                <ul class="fj-list">
                                                    <li v-for="(item,i) in scope.row.TJBFJ" :key="i">
                                                        <a target="view_window" :href="baseUrl+item.url+item.name">
                                                            {{item.name}}
                                                        </a>
                                                        <!--                                    <el-link class="fj-del" icon="el-icon-delete" @click="unsetFiles(item,i)"></el-link>-->
                                                    </li>
                                                </ul>

                                                <div slot="reference" class="name-wrapper">
                                                    <el-tag :type="scope.row.TJBFJ.length>0?'success':'danger'" size="small">已上传{{scope.row.TJBFJ.length}}个</el-tag>
                                                </div>
                                            </el-popover>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <div class="editWrap">
            <!--            详情弹窗-->
            <el-drawer :visible.sync="showAddModel" @close="closeDrawer" size="1183px" title="确认函上传"  class="editModal">
                <el-form  :hide-required-asterisk="true" :model="detail" :inline-message="true" ref="detailForm"  :inline="true" class="form-inline" v-loading="uploadLoading">
                    <div class="wrap" >
<!--                        <div class="flex" style="margin: 0px 0 12px">-->
<!--                            <div class="slfj" style="margin-top: 20px">确认函</div>-->
<!--                        </div>-->
                        <table style="text-align:center" >
                            <tr>
                                <td>
                                    <div class="label2"></div>
                                </td>
                                <td class="label3" style="width: 890px">
                                    <div >PDF电子版文件</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="label2">
                                    <div >确认函</div>
                                </td>
                                <td>
                                    <div class="flex fjWrap">
                                        <el-form-item>
                                            <label class="fright">
                                                <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                <input  style="width: 72px" type="file" v-show="false" name="file" multiple="multiple" ref="file" :id="parseInt(Math.random()*10000)" @change="onSubmit($event,'QRHFJ')" />
                                            </label>
                                        </el-form-item>
                                        <div style="display: flex;align-items: center;flex-wrap: wrap">
                                            <div class="file" v-for="(item,index) in detail.QRHFJ" :key="index">
                                                <a target="view_window" :href="baseUrl+item.url+item.name">
                                                    <img src="../assets/images/ic-fj.png" alt=""><span  style="margin-right: 6px;color: #409eff">{{item.name}} </span>
                                                </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'QRHFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="label2">
                                    <div >耕地和永久基本农田划定重大建设项目参考数据确认情况统计表</div>
                                </td>
                                <td>
                                    <div class="flex fjWrap">
                                        <el-form-item>
                                            <label class="fright">
                                                <i aria-hidden="true" class="upload-demo cursor el-button el-button--primary el-button--small">上传</i>
                                                <input  style="width: 72px" type="file" v-show="false" name="file" multiple="multiple" ref="file" :id="parseInt(Math.random()*10000)" @change="onSubmit($event,'TJBFJ')" />
                                            </label>
                                        </el-form-item>
                                        <div style="display: flex;align-items: center;flex-wrap: wrap">
                                            <div class="file" v-for="(item,index) in detail.TJBFJ" :key="index">
                                                <a target="view_window" :href="baseUrl+item.url+item.name">
                                                    <img src="../assets/images/ic-fj.png" alt=""><span  style="margin-right: 6px;color: #409eff">{{item.name}} </span>
                                                </a>
                                                <el-popconfirm title="是否删除此条信息?" @confirm="deleted(index,'TJBFJ')">
                                                    <template #reference>
                                                        <i style="color: #666666" class="el-icon-delete" ></i>
                                                    </template>
                                                </el-popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="footer">
                        <el-button type="primary" @click="save">保存</el-button>
                        <el-button @click="cancel">取消</el-button>
                    </div>
                </el-form>
            </el-drawer>
        </div>
    </div>
</template>
<script>
    import { exportData } from "../js/util/export.js"; //导出表格
    import axios from "axios";
    import store from '../store/index'
    import http from "../api/http";
    export default {
        name: "countrySide",
        components: {},
        data() {
            return {
                JSDW:'',
                dialogTableVisibleMap:false,
                dialogTableVisibleData:{},
                roleName:'',
                required: false,
                title:'新增建设单位',
                showAddModel: false,
                lineHeight:500,
                uploadLoading:false,
                detail:{QRHFJ:[],TJBFJ:[]},
                showMessage:false,
                baseUrl: "http://82.156.50.94:8002/",
                XMMC:'',
                addStatus: true,
                tableHeight:0, //表格高度
                /**查询条件 begin*/
                xmmc: "", //项目名称
                checkedJsxz: [], //建设性质
                checkedXmlx: [], //项目类型
                region: [""], //已选中行政区划
                /**查询条件 end*/
                checkList: [],
                jsxzList: [{value:'新建',label:'新建',disabled: false},{value:'改扩建',label:'改扩建',disabled: false}],
                xmlxList: [{value:'乡镇通三级及以上公路',label:'乡镇通三级及以上公路',disabled: false},{value:'较大人口规模自然村通硬化路',label:'较大人口规模自然村通硬化路',disabled: false},{value:'建制村通双车道',label:'建制村通双车道',disabled: false},{value:'资源路',label:'资源路',disabled: false},{value:'旅游路',label:'旅游路',disabled: false},{value:'产业路',label:'产业路',disabled: false},{value:'公路提质改造',label:'公路提质改造',disabled: false}],
                currentPage: 1, //当前页
                currentPage1: 1, //当前页
                pageSize: 30, //每页记录数
                pageSize1: 10, //每页记录数
                regions: [
                    {
                        value: "",
                        label: "黑龙江省",
                    },
                ],
                tableData: [], //请求列表
                total: 0, //请求记录数
                searchAll:[],
                newAdd:false,
                showEdit:false,
                isAdmin: false,
                sortData: {
                    field: "XMXH",
                    sort: "asc",
                },
            };
        },
        mounted() {
            var that = this
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 150;
            }, 100);
            this.getRegion();
            this.search();
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{

            })
            // this.getCompanyList()
            // this.getFRDW()
        },
        computed:{

        },
        methods: {
            showMaps(row) {
                this.http.post('/api/Ncgl_flag/getItemById?id='+row.ID,{}).then(res=>{
                    // if(res.status){
                    row.XZFW = res.XZFW
                    row.BaseInfoID = res.BaseInfoID ? res.BaseInfoID : row.ID
                    row.QQID = res.ID!='00000000-0000-0000-0000-000000000000' ? res.ID : row.ID
                    this.dialogTableVisibleData = row;
                    this.dialogTableVisibleMap = true;
                    // }else{
                    //     this.$message.error(res.message)
                    // }
                })
            },
            thousandBitSeparator(num) {
                var reg=/\d{1,3}(?=(\d{3})+$)/g;
                return (num + '').replace(reg, '$&,');
            },
            changeSort(val) {
                if (val.order) {
                    this.sortData = {
                        field: val.prop,
                        sort: val.order == "ascending" ? "asc" : "desc",
                    };
                    if (val.prop == "SZDS") {
                        this.sortData.field = "DSBM";
                    }
                    if (val.prop == "SZX") {
                        this.sortData.field = "QXBM";
                    }
                } else {
                    this.sortData = {
                        field: "XMXH",
                        sort: "asc",
                    };
                }
                this.tableData = [];
                this.search();
            },

            closeDrawer(){
                this.$refs.detailForm.resetFields()
            },
            cellClass(row){
                if (row.columnIndex === 0) {
                    return 'DisableSelection'
                }
            },
            checkZZS  (rule, value, callback)  {
                if (!value) {
                    return callback(new Error('所填项不能为空'));
                }
                var reg =  /^[+]{0,1}(\d+)$/
                setTimeout(() => {
                    if (!reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkNum  (rule, value, callback)  {
                if (!value) {
                    callback();
                }
                var reg =  /^\d+(\.\d+)?$/
                setTimeout(() => {
                    if (value && !reg.test(value)) {
                        callback(new Error('请输入数字值'));
                    } else {
                        callback();
                    }
                }, 0);
            },
            checkEdit(rule, value, callback){
                if (this.newAdd == false){
                    callback();
                }else{
                    if (!value) {
                        callback(new Error('请输入当前内容'));
                    }else{
                        callback();
                    }
                }
            },
            checkNull(rule, value, callback){
                // if (this.newAdd == false){
                //
                //     return false
                // }
                if (!value) {
                    callback(new Error('请输入当前内容'));
                }else{
                    callback();
                }
            },
            download(url, fileName) {
                //下载导出的文件
                let xmlResquest = new XMLHttpRequest();
                xmlResquest.open("GET", url, true);
                xmlResquest.setRequestHeader("Content-type", "application/json");
                xmlResquest.setRequestHeader(
                    "Authorization",
                    store.getters.getToken()
                );
                let elink = document.createElement("a");
                xmlResquest.responseType = "blob";
                xmlResquest.onload = function(oEvent) {
                    if (xmlResquest.status != 200) {
                        this.$error("下载文件出错了..");
                        return;
                    }
                    let content = xmlResquest.response;
                    elink.download = fileName; //+".xlsx";
                    // elink.style.display = "none";
                    let blob = new Blob([content]);
                    elink.href = URL.createObjectURL(blob);
                    // document.body.appendChild(elink);
                    elink.click();
                    //  document.body.removeChild(elink);
                };
                xmlResquest.send();
            },
            downLoadZip(row){
                var that = this
                this.http.get('/api/Plan_high_national_early/getFilePackage?id='+row.ID,{}).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        name+'.zip'
                    );
                })
            },
            //搜索条件
            searchData(){
                var searchData = {
                    page: this.currentPage1,
                    rows: this.pageSize1,
                    wheres:JSON.stringify([{Name:'XMLX',Value:'Road',DisplayType:'Equal'}]),
                };
                return searchData
            },
            confirmChoose(){

            },
            addRoad(){
                var that = this
                this.addStatus = true
                this.currentPage1 = 1
                this.showAddModel = true
                this.detail = {QRHFJ:[],TJBFJ:[]}
            },
            //退出编辑
            closeEdit(){
                this.showMessage = false
                this.showEditModel = false
                this.showAddModel = false
                this.detail = {QRHFJ:[],TJBFJ:[]}
            },
            //删除图片
            deleted(index,type){
                this.detail[type].splice(index,1)
            },
            setTimes(time) {
                if (!time) return time;
                let dt = new Date(time);
                let yyyy = dt.getFullYear();
                let MM = (dt.getMonth() + 1).toString().padStart(2, "0");
                let dd = dt.getDate().toString().padStart(2, "0");
                return yyyy + "-" + MM + "-" + dd;
            },
            dealSaveFj(data){
                var pfwjString = []
                data.map((item)=>{
                    pfwjString.push(item.url+item.name)
                })
                return pfwjString.join(';')
            },
            save(){
                this.$refs.detailForm.validate((valid) => {
                    if (valid) {
                        var params = JSON.parse(JSON.stringify(this.detail))
                        console.log(params)
                        params.QRHFJ = this.dealSaveFj(params.QRHFJ)
                        params.TJBFJ = this.dealSaveFj(params.TJBFJ)
                        if(this.addStatus){
                            params.id='00000000-0000-0000-0000-000000000000'
                            this.http.post('/api/Plan_road_confirmation/AddConfirmation',params).then(res=>{
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                    this.$refs.detailForm.resetFields()
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }else{
                            this.http.post('/api/Plan_road_confirmation/UpdateConfirmation',params).then(res=>{
                                if(res.status){
                                    this.showAddModel = false
                                    this.search()
                                    this.$message.success(res.message)
                                    this.$refs.detailForm.resetFields()
                                    this.addStatus = true
                                }else{
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    }
                });
            },
            cancel(){
                this.showMessage = true
                this.showAddModel = false

            },
            getCaption(obj){
                var index=obj.lastIndexOf(".");
                obj=obj.substring(index+1,obj.length);
                return obj;
            },
            onSubmit(e, type) {
                this.uploadLoading = true
                let file = e.target.files;
                let form = new FormData();
                let arrs = [];
                let hz =  this.getCaption(file[0].name)
                if (hz != 'pdf' && hz != 'PDF' ){
                    this.$message.warning('请上传pdf格式文件')
                    this.uploadLoading = false
                    return false
                }
                console.log(e,type,hz)

                file.forEach((element) => {
                    form.append("fileInput", element);
                    arrs.push(element.name);
                });
                console.log(this.detail[type])
                this.http.post("/api/Plan_road_confirmation/upload", form)
                    .then((res) => {
                        this.uploadLoading = false
                        arrs.forEach((v) => {
                            // this.details[type].push(res.data + v);
                            this.detail[type].push({url:res.data, name:v})

                        });
                        // this.detail[type].pfwj.push({url:res.data, name:file[0].name})
                    });

            },
            dealFj(data){
                var a = []
                if(data != null && data != undefined && data != ''){
                    data = data.split(';')
                    data.map((subItem)=>{
                        subItem = {url:subItem.substring(0,subItem.lastIndexOf("/") + 1),name:subItem.substring(subItem.lastIndexOf("/") + 1,subItem.length)}
                        a.push(subItem)
                    })
                    return  a
                }else {
                    return  []
                }

                // if (data != null || data != undefined){
                //  return  {url:data.substring(0,data.lastIndexOf("/") + 1),name:data.substring(data.lastIndexOf("/") + 1,data.length)}
                // }else{
                //     return []
                // }
            },

            openDetailsEdit(row) {
                var that = this
                this.showAddModel = true
                this.addStatus = false
                this.http.post('/api/Plan_road_confirmation/getItemById?id='+row.ID,{}).then(res=>{
                    res.QRHFJ = this.dealFj(res.QRHFJ)
                    res.TJBFJ = this.dealFj(res.TJBFJ)
                    this.detail = res
                })
            },
            exportData() {
                var that = this
                this.http.post('/api/Plan_high_national_early/templateWrite',this.postData()).then(res=>{
                    if(!res.status){
                        this.$message.error(res.message)
                        return false
                    }
                    let path = "/api/" + 'Plan_high_national_early' + "/DownLoadFile";
                    path = path[0] == "/" ? path.substring(1) : path;
                    var timestamp = new Date().getTime();
                    // var name = row.XMMC == '' || row.XMMC ==null ? row.SSWGHXMMC :row.XMMC
                    this.download(
                        that.baseUrl + path + "?path=" + res.data,
                        '前期项目表'+timestamp+'.xlsx'
                    );
                })
                // exportData("/api/Plan_high_national_early/templateWrite", this.postData());
            },
            getRegion() {
                var postData = { SZDS: "", SZX: "" };
                this.http
                    .post("/api/Base_area/getAdminDivTree", postData)
                    .then((res) => {
                        res.map((r) => {
                            r.value = r.label;
                            r.children.map((rr) => {
                                rr.value = rr.label;
                            });
                        });
                        this.regions = res;

                    });
            },
            //查询
            search() {
                this.$refs.table.bodyWrapper.scrollTop = 0; //滚动条回顶部
                this.http
                    .post(
                        "/api/Plan_road_confirmation/GetData",
                        this.postData(),
                        "正在请求数据，请稍候..."
                    )
                    .then((res) => {
                        this.getRegion()
                        if (res.status == 0) {
                            this.total = res.total;
                            this.tableData = res.rows;
                            this.tableData.map((item,index)=>{
                            item.QRHFJ = this.dealFj(item.QRHFJ)
                                item.TJBFJ = this.dealFj(item.TJBFJ)
                            })
                        }
                    });
            },
            postData() {
                // var szds = "";
                // var ssx = "";
                // var reg = JSON.parse(JSON.stringify(this.region))
                // if (reg.length > 0) {
                //     szds = reg[0];
                //     if (reg.length > 1) {
                //         ssx = reg[1];
                //     }
                // }

                //项目名称
                var query_xmmc = {
                    Name: "JSDW",
                    Value: this.JSDW,
                    DisplayType: "like",
                };
                var postData = {
                    page: this.currentPage,
                    rows: this.pageSize,
                    Sort: "",
                    Order: "asc",
                    wheres: JSON.stringify([
                        query_xmmc,
                    ]),
                };
                return postData;
            },
            clearCondition() {
                this.JSDW = "";
            },
            handleSizeChange(val) {
                this.pageSize = val;
                this.search();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.search();
            },
        },
        watch: {
            activeIndex(v) {
                if (v) {
                    this.tableHeight = document.body.offsetHeight - 450;
                } else {
                    this.tableHeight = document.body.offsetHeight - 245;
                }
            },
        },
    };
</script>
<style>
    .hidden {
        display: none ;
    }
</style>
<style scoped lang="less">
    .fl-jus {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .label {
        margin-right: 8px;
        width: 126px;
        height: 42px;
        background: #fafafa;
        line-height: 42px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label2 {
        padding: 10px;
        width: 240px;
        height: 60px;
        background: #fafafa;
        line-height: 36px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .label3 {
        width: 478px;
        height: 36px;
        background: #fafafa;
        line-height: 36px;
        color: #000000;
        font-weight: 600;
        text-align: center;
    }
    .cont {
        width: 220px;
        height: 36px;
        text-align: center;
        line-height: 36px;
    }
    body {
        background: #ffffff !important;
    }
    .flex {
        display: flex;
        align-items: center;
    }
    .delete {
        font-size: 16px;
        color: #f56c6c;
    }
    .dialog-footer {
        width: 100%;
        height: 47px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        background: #f8f8f8;
        align-items: center;
        justify-content: center;
    }
    .wrap {
        padding: 17px 19px;
        /deep/.el-form-item {
            margin: 0;
        }
        /deep/.el-input__inner {
            height: 32px;
        }
        /deep/.el-input--medium .el-input__inner {
            height: 32px;
            line-height: 32px;
        }
        table {
            border-collapse: collapse;
        }
        td {
            border: 1px solid #dddddd;
            .cont {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .slfj {
        text-align: center;
        width: 98px;
        height: 32px;
        background: #409eff;
        opacity: 1;
        border-radius: 0px 16px 16px 0px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        line-height: 32px;
        margin-right: 17px;
    }
    .star {
        font-size: 14px;
        font-weight: bold;
        color: #f56c6c;
    }
    .fjWrap {
        padding: 3px 13px;
        align-items: flex-start;
        .fright {
            margin-right: 12px;
        }
    }
    .file {
        margin-right: 10px;
        margin-top: 5px;
        padding: 0 6px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #b3d8ff;
        opacity: 1;
        border-radius: 4px;
        color: #409eff;
        display: flex;
        align-items: center;
        div {
            cursor: pointer;
            max-width: 408px;
            text-overflow: ellipsis;
            white-space: nowrap;
            height: 32px;
            line-height: 32px;
            display: flex;align-items: center
        }
    }
    .dialog-footer {
        width: 100%;
        height: 47px;
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        background: #f8f8f8;
        align-items: center;
        justify-content: center;
    }
    .footer{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 47px;
        background: #F8F8F8;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

</style>
